import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesLanding from "../../../components/pages/templates/TemplatesLanding";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";

import eShopImage from "../../../components/pages/home/HomeExamples/e_shop_cover.png";
import landoImage from "../../../components/pages/home/HomeExamples/lando_cover.png";
import mobileWireframeImage from "../../../components/pages/home/HomeExamples/mobile_wireframe_cover.png";
import productivityAppImage from "../../../components/pages/home/HomeExamples/productivity_app_cover.png";
import gmdeaImage from "../../../components/pages/home/HomeExamples/GMDEA_cover.png";
import webWireframeImage from "../../../components/pages/home/HomeExamples/web_wireframe_cover.png";
import travelAppImage from "../../../components/pages/home/HomeExamples/travel_app_cover.png";
import basketImage from "../../../components/pages/home/HomeExamples/basket_cover.png";
import materialYouImage from "../../../components/pages/home/HomeExamples/Google_Material_You_cover.png";
import estateImage from "../../../components/pages/home/HomeExamples/estate_cover.png";
import gmdwaImage from "../../../components/pages/home/HomeExamples/GMDWA_cover.png";
import carbonImage from "../../../components/pages/home/HomeExamples/IBM_Carbon_cover.png";
import embarkImage from "../../../components/pages/home/HomeExamples/embark_cover.png";
import iOSImage from "../../../components/pages/home/HomeExamples/ios15_cover.png";
import quickBiteImage from "../../../components/pages/home/HomeExamples/food-delivery-web-app-cover.png";
import web3Image from "../../../components/pages/home/HomeExamples/web3_crypto_website_cover.png";
import petShopImage from "../../../components/pages/home/HomeExamples/pet_web_store_cover.png";
import messagingAppImage from "../../../components/pages/home/HomeExamples/messaging_app_cover.png";
import hrAppImage from "../../../components/pages/home/HomeExamples/hr_app_cover.png";
import pitchDeckImage from "../../../components/pages/home/HomeExamples/online_interactive_pitch_deck_cover.png";
import onlineBankingImage from "../../../components/pages/home/HomeExamples/online_banking_web_app_cover.png";
import sportAppImage from "../../../components/pages/home/HomeExamples/sports_mobile_app_cover.png";
import quickBiteMobileImage from "../../../components/pages/home/HomeExamples/food_delivery_mobile_app_cover.png";
import musicMobileAppImage from "../../../components/pages/home/HomeExamples/music_mobile_app_cover.png";
import cryptoMobileAppImage from "../../../components/pages/home/HomeExamples/crypto_nft_mobile_app_cover.png";

import blogImage from "../../../components/pages/templates/componentTemplates/blog.png";
import cardImage from "../../../components/pages/templates/componentTemplates/card.png";
import dialogImage from "../../../components/pages/templates/componentTemplates/dialog.png";
import footerImage from "../../../components/pages/templates/componentTemplates/footer.png";
import formImage from "../../../components/pages/templates/componentTemplates/form.png";
import galleryImage from "../../../components/pages/templates/componentTemplates/gallery.png";
import headerImage from "../../../components/pages/templates/componentTemplates/header.png";
import listImage from "../../../components/pages/templates/componentTemplates/list.png";
import multimediaImage from "../../../components/pages/templates/componentTemplates/multimedia.png";
import navImage from "../../../components/pages/templates/componentTemplates/nav.png";
import panelImage from "../../../components/pages/templates/componentTemplates/panel.png";
import shopImage from "../../../components/pages/templates/componentTemplates/shop.png";
import socialImage from "../../../components/pages/templates/componentTemplates/social.png";
import tableImage from "../../../components/pages/templates/componentTemplates/table.png";

const Templates = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Meta
        title="Uizard Templates | Bring Your Designs To Life | Uizard"
        description="Never start from scratch again. Bring your ideas to life using Uizard templates. With a range to choose from, there is something for everyone."
        url="/lp/templates/"
        noindex={true}
      />

      <TemplatesLanding
        imageA={data.comparisonScreensImageA.childImageSharp}
        imageB={data.comparisonScreensImageB.childImageSharp}
        headline="Pre-designed web & app mockup templates"
        description="Never start from scratch again. Bring your ideas to life in seconds with Uizard's magical prototype templates."
      />

      <TemplatesSection
        title="Mobile App Templates"
        description="Start your project from a collection of pre-made mobile app designs to get running in no time."
        linkPage="/templates/mobile-app-templates/"
        thumbnails={[
          {
            image: productivityAppImage,
            alt: "Productiviy mobile app project image",
            title: "Productivity mobile app",
            link: "/templates/mobile-app-templates/productivity-mobile-app/",
          },
          {
            image: travelAppImage,
            alt: "Travel mobile app project image",
            title: "Travel mobile app",
            link: "/templates/mobile-app-templates/travel-mobile-app/",
          },
          {
            image: mobileWireframeImage,
            alt: "Mobile wireframe example project image",
            title: "Mobile app wireframe",
            link: "/templates/mobile-app-templates/mobile-app-wireframe/",
          },
          {
            image: basketImage,
            alt: "Basket sport clothing shopping app example project image",
            title: "Sport clothing shopping app",
            link: "/templates/mobile-app-templates/sport-clothing-shopping-app/",
          },
          {
            image: gmdeaImage,
            alt: "Google Material Design mobile client app project image",
            title: "Material Design email mobile app",
            link: "/templates/mobile-app-templates/google-material-design-mobile-email-app/",
          },
          {
            image: materialYouImage,
            alt: "Google Material You android app project image",
            title: "Google Material You Android app",
            link: "/templates/mobile-app-templates/google-material-you/",
          },
          {
            image: iOSImage,
            alt: "iOS 15 mobile app project image",
            title: "iOS 15 mobile app",
            link: "/templates/mobile-app-templates/ios-mobile-app/",
          },
          {
            image: messagingAppImage,
            alt: "Instant messaging app project image",
            title: "Instant messaging app",
            link: "/templates/mobile-app-templates/messaging-app/",
          },
          {
            image: sportAppImage,
            alt: "Sport mobile app project image",
            title: "Sport mobile app",
            link: "/templates/mobile-app-templates/sports-app/",
          },
          {
            image: quickBiteMobileImage,
            alt: "Food delivery mobile app project image",
            title: "Food delivery mobile app",
            link: "/templates/mobile-app-templates/mobile-food-delivery-app/",
          },
          {
            image: musicMobileAppImage,
            alt: "Music streaming mobile app project image",
            title: "Music streaming mobile app",
            link: "/templates/mobile-app-templates/music-streaming-app/",
          },
          {
            image: cryptoMobileAppImage,
            alt: "Crypto NFT mobile app project image",
            title: "Crypto NFT mobile app",
            link: "/templates/mobile-app-templates/crypto-nft-mobile-app/",
          },
        ]}
      />

      <TemplatesSection
        title="Web App Templates"
        description="The perfect place to start if you are building a web app, a SaaS, or an interactive web-based experience."
        linkPage="/templates/web-app-templates/"
        thumbnails={[
          {
            image: gmdwaImage,
            alt: "Google Material Design Web App project image",
            title: "Google Material Design web app",
            link: "/templates/web-app-templates/google-material-design-web-app/",
          },
          {
            image: carbonImage,
            alt: "IBM Carbon dashboard web app project image",
            title: "IBM Carbon cloud dashboard",
            link: "/templates/web-app-templates/ibm-carbon-cloud-dashboard-web-app/",
          },
          {
            image: quickBiteImage,
            alt: "Food delivery web app project image",
            title: "Online food delivery app",
            link: "/templates/web-app-templates/online-food-delivery/",
          },
          {
            image: hrAppImage,
            alt: "Human resources web app project image",
            title: "Human resources web app",
            link: "/templates/web-app-templates/hr-web-app/",
          },
          {
            image: pitchDeckImage,
            alt: "Interactive online pitch deck project image",
            title: "Interactive online pitch deck",
            link: "/templates/web-app-templates/interactive-online-pitch-deck/",
          },
          {
            image: onlineBankingImage,
            alt: "Online banking web app project image",
            title: "Online banking web app",
            link: "/templates/web-app-templates/online-banking-web-app/",
          },
        ]}
      />

      <TemplatesSection
        title="Website Templates"
        description="Beautiful complete pre-made web pages ready to customize for your own projects."
        linkPage="/templates/website-templates/"
        thumbnails={[
          {
            image: landoImage,
            alt: "Startup landing page Lando example project image",
            title: "Startup landing page",
            link: "/templates/website-templates/startup-landing-page/",
          },
          {
            image: eShopImage,
            alt: "E-shop example project image",
            title: "E-commerce website",
            link: "/templates/website-templates/e-commerce-website/",
          },
          {
            image: webWireframeImage,
            alt: "Website wireframe example project image",
            title: "Website wireframe",
            link: "/templates/website-templates/website-wireframe/",
          },
          {
            image: estateImage,
            alt: "Real Estate website example project image",
            title: "Real estate website",
            link: "/templates/website-templates/real-estate-website/",
          },
          {
            image: embarkImage,
            alt: "Business Home Page project image",
            title: "Business home page",
            link: "/templates/website-templates/business-home-page/",
          },
          {
            image: web3Image,
            alt: "Web3 crypto website project image",
            title: "Web3 crypto website",
            link: "/templates/website-templates/web3-crypto-website/",
          },
          {
            image: petShopImage,
            alt: "Online pet shop project image",
            title: "Online pet shop",
            link: "/templates/website-templates/pet-store/",
          },
        ]}
      />

      <TemplatesSection
        title="UI Components Library"
        description="The lego bricks for designing mobile apps, websites, and desktop software."
        linkPage="/templates/component-templates/"
        thumbnails={[
          {
            image: cardImage,
            alt: "card template image",
            title: "Card",
            link: "/templates/component-templates/card/",
          },
          {
            image: blogImage,
            alt: "Content and blog template image",
            title: "Content",
            link: "/templates/component-templates/content/",
          },
          {
            image: dialogImage,
            alt: "dialog template image",
            title: "Dialog",
            link: "/templates/component-templates/dialog/",
          },
          {
            image: footerImage,
            alt: "footer template image",
            title: "Footer",
            link: "/templates/component-templates/footer/",
          },
          {
            image: formImage,
            alt: "form template image",
            title: "Form",
            link: "/templates/component-templates/form/",
          },
          {
            image: galleryImage,
            alt: "gallery template image",
            title: "Gallery",
            link: "/templates/component-templates/gallery/",
          },
          {
            image: headerImage,
            alt: "header template image",
            title: "Header",
            link: "/templates/component-templates/header/",
          },
          {
            image: listImage,
            alt: "list template image",
            title: "List",
            link: "/templates/component-templates/list/",
          },
          {
            image: multimediaImage,
            alt: "multimedia template image",
            title: "Multimedia",
            link: "/templates/component-templates/multimedia/",
          },
          {
            image: navImage,
            alt: "navigation template image",
            title: "Navigation",
            link: "/templates/component-templates/navigation/",
          },
          {
            image: panelImage,
            alt: "panel template image",
            title: "Panel",
            link: "/templates/component-templates/panel/",
          },
          {
            image: shopImage,
            alt: "shop template image",
            title: "Shop",
            link: "/templates/component-templates/shop/",
          },
          {
            image: socialImage,
            alt: "social template image",
            title: "Social",
            link: "/templates/component-templates/social/",
          },
          {
            image: tableImage,
            alt: "table template image",
            title: "Table",
            link: "/templates/component-templates/table/",
          },
        ]}
      />

      <Content>
        <Shoutout
          headline="Easy screen design for non-designers"
          description="Create your project today"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Content>
    </Layout>
  );
};

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    comparisonScreensImageA: file(
      relativePath: { eq: "comparison/screens-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageB: file(
      relativePath: { eq: "comparison/screens-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
